<template>
  <div>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <KTCodePreview v-bind:title="'Danh sách chương trình thưởng NH máy'">
      <template v-slot:toolbar>
        <div class="row">
          <div class="ml-3">
            <router-link to="/reward-phone/info-reward-phone">
              <b-button v-bind:style="btnCreate" variant="primary" size="sm">
                <i style="font-size: 1rem" class="flaticon2-add-1"></i>Thêm mới
              </b-button>
            </router-link>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <!-- Header session -->
        <div class="col-md-12 mb-5">
          <b-form>
            <b-row>
              <b-col style="padding-left: 0">
                <b-input
                  placeholder="Tìm kiếm"
                  v-model="search"
                  append-icon="search"
                  single-line
                  hide-details
                  class="btn-sm"
                  v-on:input="fetchData()"
                ></b-input>
              </b-col>
              <b-col></b-col>
              <b-col></b-col>
              <b-col></b-col>
            </b-row>
          </b-form>
        </div>
        <!-- End of Header session -->
        <!-- Table session -->
        <b-table
          :items="items"
          :fields="fields"
          bordered
          hover
          :busy="onLoading"
        >
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
          </template>
          <template v-slot:cell(status)="row">
            <span
              v-if="checkStatus(row.item)"
              v-text="row.item.statusName"
              class="
                label
                font-weight-bold
                label-lg label-light-success label-inline
              "
            ></span>
            <span
              v-else
              v-text="row.item.statusName"
              class="
                label
                font-weight-bold
                label-lg label-light-danger label-inline
              "
            ></span>
          </template>
          <template v-slot:cell(createdAt)="row">
            <p class="text-center">{{ row.item.createdAt }}</p>
            <p class="text-center">{{ row.item.createdBy }}</p>
          </template>
          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>
                <b-dropdown-item @click="copyItem(row.item)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="far fa-copy"></i>
                    &nbsp; Sao chép
                  </span>
                </b-dropdown-item>
                <b-dropdown-item @click="editItem(row.item)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-pen"></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDeleteAlert(row.item)"
                  v-if="checkPermission('REWARD_DELETE')"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem; color: #d33"
                      class="flaticon2-rubbish-bin-delete-button"
                    ></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <!-- End of Table session -->

        <!-- Paginate session -->
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số :
              {{ totalItems }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              class="custom-pagination"
              v-show="totalPages >= 2"
              :link-gen="linkGen"
              :number-of-pages="totalPages"
              use-router
              @change="fetchData()"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
              prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
        <!-- End of Paginate session -->
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import localData from '../../../utils/saveDataToLocal';
import { mapGetters } from 'vuex';
import Loader from '@/view/content/Loader.vue';
import { VclTable } from 'vue-content-loading';

export default {
  data() {
    return {
      items: [],
      onLoading: false,
      btnCreate: {
        fontWeight: '600!important',
      },
      totalPages: 1,
      totalItems: 0,
      page: 1,
      search: '',
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      fields: [
        {
          key: 'count',
          label: 'STT',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '5%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'name',
          label: 'Chương trình',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'description',
          label: 'Mô tả',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '25%' },
        },
        {
          key: 'startDate',
          label: 'Ngày bắt đầu',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'endDate',
          label: 'Ngày kết thúc',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'status',
          label: 'Trạng thái',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        { key: 'actions', label: '' },
      ],
    };
  },
  components: {
    KTCodePreview,
    Loader,
    VclTable,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: 'Chương trình thưởng NH máy',
        route: 'reward-phone',
      },
      { title: 'Danh sách chương trình thưởng NH máy' },
    ]);
  },
  created() {
    this.fetchData();
  },
  methods: {
    editItem: function (item) {
      this.$router.push({
        name: 'info-reward-phone',
        query: { id: item.id, mode: 'update' },
      });
    },
    copyItem: function (item) {
      this.$router.push({
        name: 'info-reward-phone',
        query: { id: item.id, mode: 'create' },
      });
    },
    fetchData: async function () {
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      if (!this.search) {
        this.search = '';
      }

      const params = {
        page: this.page,
        limit: 10,
        name: this.search,
      };

      ApiService.query('/reward-phone', { params }).then((response) => {
        this.items = [];
        this.totalPages = response.data.data.totalPage;
        this.totalItems = response.data.data.totalRow;
        this.items = response.data.data.data.map((item, index) => {
          return {
            count: index + 1,
            id: item.id,
            name: item.name,
            description: item.description,
            status: item.status,
            startDate: item.startDate,
            endDate: item.endDate,
            statusName: item.statusName,
            createdBy: item.createdBy,
            createdAt: item.createdAt,
          };
        });
      });
    },
    showDeleteSuccess: function () {
      Swal.fire({
        title: 'Thông báo',
        text: 'Xóa nhân viên thành công!',
        icon: 'success',
        confirmButtonClass: 'btn btn-secondary',
      });
    },
    deleteItem: async function (item) {
      ApiService.delete(`reward-phone/${item.id}`).then((data) => {
        const { status, message } = data.data;
        if (status === 1) {
          this.makeToastSuccess(message);
          this.fetchData();
        } else {
          this.makeToastFaile(message);
        }
      });
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa chương trình thưởng hoa hồng!',
        text: 'Bạn có chắc muốn xóa chương trình thưởng hoa hồng này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    checkStatus: function (item) {
      if (item.status === 1) {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
  },
};
</script>

<style scoped>
.icon:hover {
  background-color: #90c6fc;
}

.sttClass {
  width: 20px;
}
</style>

<style>
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
</style>
